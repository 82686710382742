import { StopCircle } from 'react-feather'
import { Header } from '../../components/Header'

export default [
  {
    id: 'exit',
    title: 'Sign out',
    icon: <StopCircle/>,
    navLink: <Header/>  
  }
]
